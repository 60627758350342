@font-face {
  font-family: 'TTNorms';
  font-weight: 100;
  src: local('TTNorms'), url(../fonts/TTNorms/TTNormsPro-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'TTNorms';
  font-weight: 300;
  src: local('TTNorms'), url(../fonts/TTNorms/TTNormsPro-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'TTNorms';
  font-weight: 400;
  src: local('TTNorms'), url(../fonts/TTNorms/TTNormsPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'TTNorms';
  font-weight: 500;
  src: local('TTNorms'), url(../fonts/TTNorms/TTNormsPro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'TTNorms';
  font-weight: 700;
  src: local('TTNorms'), url(../fonts/TTNorms/TTNormsPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'TTNorms';
  font-weight: 900;
  src: local('TTNorms'), url(../fonts/TTNorms/TTNormsPro-Black.ttf) format('truetype');
}

body {
  font-family: 'TTNorms', sans-serif;
  -webkit-font-smoothing: antialiased;
  & textarea {
    font-family: 'TTNorms', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
}