@import "fonts";
@import "colors";
@import "breakpoints";

html.avif {
  & body {
    background-image: url(../images/Path/Path390.svg);
    @media (min-width: $breakpoint-tablet) {
      background-image: url(../images/Path/Path768.svg);
    }
    @media (min-width: $breakpoint-laptop) {
      background-image: image-set(
        url(../images/Path/Path1280.avif) 1x,
        url(../images/Path/Path1280@2x.avif) 2x,
        url(../images/Path/Path1280@3x.avif) 3x
      );
    }
    @media (min-width: $breakpoint-fullHD) {
      background-image: image-set(
          url(../images/Path/Path.avif) 1x,
          url(../images/Path/Path@2x.avif) 2x,
          url(../images/Path/Path@3x.avif) 3x
      );
    }
  }
}

html.notAvif {
  & body {
    background-image: url(../images/Path/Path390.svg);
    @media (min-width: $breakpoint-laptop) {
      background-image: image-set(
        url(../images/Path/Path1280.png) 1x,
        url(../images/Path/Path1280@2x.png) 2x,
        url(../images/Path/Path1280@3x.png) 3x
      );
    }
    @media (min-width: $breakpoint-fullHD) {
      background-image: image-set(
        url(../images/Path/Path.png) 1x,
        url(../images/Path/Path@2x.png) 2x,
        url(../images/Path/Path@3x.png) 3x
      );
    }
  }
}

body {
  margin: 0;
  background-color: var(--background-primary);
  background-repeat: no-repeat;
  background-size: contain;
  &.disableScroll {
    height: 100vh;
    overflow-y: hidden;
  }
}

main, .Navigation {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-left: calc((100vw - 390px) / 2);
  padding-right: calc((100vw - 390px) / 2);
  width: calc(100vw - (100vw - 390px));
  @media (min-width: $breakpoint-tablet) {
    padding-left: calc((100vw - 768px) / 2);
    padding-right: calc((100vw - 768px) / 2);
    width: calc(100vw - (100vw - 768px));
  }
  @media (min-width: $breakpoint-laptop) {
    padding-left: calc((100vw - 1280px) / 2);
    padding-right: calc((100vw - 1280px) / 2);
    width: calc(100vw - (100vw - 1280px));
  }
  @media (min-width: $breakpoint-fullHD) {
    padding-left: calc((100vw - 1920px) / 2);
    padding-right: calc((100vw - 1920px) / 2);
    width: calc(100vw - (100vw - 1920px));
  }
}