@import "../../../styles/breakpoints";

.Intro {
  padding-left: 31px;
  padding-right: 31px;
  @media (min-width: $breakpoint-tablet) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: $breakpoint-laptop) {
    padding-left: 130px;
    padding-right: 130px;
  }
  @media (min-width: $breakpoint-fullHD) {
    padding-left: 315px;
    padding-right: 315px;
  }
  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (min-width: $breakpoint-laptop) {
      flex-wrap: nowrap;
    }
    &-left {
      max-width: 514px;
      @media (min-width: $breakpoint-tablet) {
        max-width: 555px;
      }
      @media (min-width: $breakpoint-laptop) {
        max-width: 620px;
      }
      @media (min-width: $breakpoint-fullHD) {
        max-width: 640px;
      }
      &-button {
        display: none;
        @media (min-width: $breakpoint-laptop) {
          display: block;
        }
        & button {
          min-width: 231px;
          @media (min-width: $breakpoint-fullHD) {
            min-width: 272px;
          }
        }
      }
      &-title {
        font-weight: 500;
        color: var(--text-primary);
        margin-bottom: 21px;
        font-size: 30px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 48px;
          margin-bottom: 8px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 36px;
          margin-bottom: 13px;
          max-width: 420px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 50px;
          max-width: 578px;
        }
      }
      &-subTitle {
        color: var(--text-primary);
        margin-bottom: 23px;
        font-weight: 300;
        font-size: 20px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 30px;
          margin-bottom: 45px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin-bottom: 21px;
          max-width: 350px;
          font-size: 24px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 30px;
          max-width: 440px;
          margin-bottom: 38px;
        }
      }
      &-description {
        font-weight: 400;
        font-style: italic;
        color: var(--text-pentary);
        margin-bottom: 49px;
        max-width: 352px;
        font-size: 13px;
        line-height: 20px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
          max-width: 492px;
          line-height: 26px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 16px;
          margin-bottom: 20px;
          line-height: 28px;
          max-width: 440px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
          max-width: 443px;
          line-height: 34px;
          margin-bottom: 47px;
        }
      }
      &-pros {
        margin-bottom: 30px;
        max-width: 290px;
        @media (min-width: $breakpoint-tablet) {
          max-width: 583px;
          margin-bottom: 53px;
        }
        @media (min-width: $breakpoint-fullHD) {
          max-width: 640px;
        }
        &-item {
          display: flex;
          align-items: flex-start;
          &:not(:last-child) {
            margin-bottom: 3px;
            @media (min-width: $breakpoint-tablet) {
              margin-bottom: 4px;
            }
            @media (min-width: $breakpoint-laptop) {
              margin-bottom: 0;
            }
            @media (min-width: $breakpoint-fullHD) {
              margin-bottom: 4px;
            }
          }
          &-icon {
            padding-top: 7px;
            width: 9px;
            height: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            @media (min-width: $breakpoint-tablet) {
              width: 13px;
              height: 13px;
              padding-top: 9px;
            }
            & img {
              width: 9px;
              height: 9px;
              @media (min-width: $breakpoint-tablet) {
                width: 13px;
                height: 13px;
              }
            }
          }
          &-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 23px;
            color: var(--text-primary);
            @media (min-width: $breakpoint-tablet) {
              font-size: 18px;
              line-height: 34px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 14px;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 16px;
            }
          }
        }
      }
    }
    &-right {
      margin-bottom: 53px;
      width: 100%;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 69px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 0;
        width: initial;
      }
      &-screen {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 328px;
        height: 184px;
        border: 2px solid #c8c6dd;
        border-radius: 10px;
        @media (min-width: $breakpoint-tablet) {
          width: 560px;
          height: 315px;
        }
        @media (min-width: $breakpoint-laptop) {
          width: 395px;
          height: 222px;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 551px;
          height: 311px;
        }
        & iframe {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
      }
    }
    &-buttonMobile {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      @media (min-width: $breakpoint-laptop) {
        display: none;
      }
      & button {
        min-width: 221px;
        @media (min-width: $breakpoint-tablet) {
          min-width: 318px;
        }
      }
    }
  }
}
