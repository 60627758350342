@import "../../../styles/breakpoints";

.Beta {
  &-content {
    &-title {
      margin-bottom: 57px;
      margin-left: auto;
      margin-right: auto;
      max-width: 297px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 103px;
        max-width: 594px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 111px;
        max-width: 445px;
      }
      @media (min-width: $breakpoint-fullHD) {
        margin-bottom: 130px;
        max-width: 494px;
      }
      & .Title-description {
        max-width: 220px;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: $breakpoint-tablet) {
          max-width: 590px;
        }
        @media (min-width: $breakpoint-laptop) {
          max-width: 390px;
        }
        @media (min-width: $breakpoint-fullHD) {
          max-width: 420px;
        }
      }
    }
    &-inside {
      margin: 0 auto;
      max-width: 323px;
      @media (min-width: $breakpoint-tablet) {
        max-width: 440px;
      }
      @media (min-width: $breakpoint-laptop) {
        max-width: 500px;
      }
      @media (min-width: $breakpoint-fullHD) {
        max-width: 500px;
      }
      &-item {
        &:not(:last-child) {
          margin-bottom: 13px;
          @media (min-width: $breakpoint-tablet) {
            margin-bottom: 24px;
          }
          @media (min-width: $breakpoint-laptop) {
            margin-bottom: 13px;
          }
          @media (min-width: $breakpoint-fullHD) {
            margin-bottom: 26px;
          }
        }
        &-first {
          display: flex;
          align-items: center;
          margin-bottom: 13px;
          @media (min-width: $breakpoint-tablet) {
            margin-bottom: 24px;
          }
          @media (min-width: $breakpoint-laptop) {
            margin-bottom: 13px;
          }
          @media (min-width: $breakpoint-fullHD) {
            margin-bottom: 26px;
          }
          &-left {
            margin-right: 33px;
          }
          &-right {
            color: var(--text-primary);
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            @media (min-width: $breakpoint-tablet) {
              font-size: 18px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 15px;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 16px;
            }
          }
        }
        &-second {
          display: flex;
          align-items: flex-start;
          &-left {
            min-width: 37px;
            display: flex;
            justify-content: center;
            margin-right: 33px;
            @media (min-width: $breakpoint-tablet) {
              min-width: 59px;
            }
            @media (min-width: $breakpoint-laptop) {
              min-width: 53px;
            }
            &-separator {
              height: 55px;
              width: 2px;
              background-color: var(--separator);
              opacity: 0.2;
              @media (min-width: $breakpoint-tablet) {
                height: 74px;
              }
              @media (min-width: $breakpoint-laptop) {
                height: 79px;
              }
            }
          }
          &-right {
            color: var(--text-primary);
            opacity: 0.5;
            font-size: 12px;
            line-height: 1.71;
            max-width: 419px;
            margin-top: -5px;
            @media (min-width: $breakpoint-tablet) {
              font-size: 18px;
              line-height: 28px;
              margin-top: -22px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 15px;
              margin-top: 0;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}