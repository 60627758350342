@import "../../../styles/breakpoints";

.Title {
  &-main {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &-plus {
      font-size: 33px;
      font-weight: 500;
      color: var(--text-ternary);
      padding-bottom: 5px;
      margin-right: 9px;
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 36px;
        display: block;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 50px;
      }
    }
    &-text {
      font-size: 24px;
      color: var(--text-primary);
      font-weight: 400;
      @media (min-width: $breakpoint-tablet) {
        font-size: 48px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 36px;
        text-align: left;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 40px;
      }
    }
    &-accent {
      font-size: 24px;
      color: var(--text-accent);
      font-weight: 500;
      @media (min-width: $breakpoint-tablet) {
        font-size: 48px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 36px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 40px;
      }
    }
  }
  &-description {
    font-size: 13px;
    text-align: center;
    font-weight: 400;
    color: var(--text-primary);
    line-height: 24px;
    margin-top: 20px;
    @media (min-width: $breakpoint-tablet) {
      margin-top: 33px;
      font-size: 18px;
      line-height: 32px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-top: 35px;
      font-size: 14px;
      line-height: 32px;
      text-align: left;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-top: 35px;
      font-size: 16px;
      line-height: 40px;
    }
    & .accent {
      color: var(--text-accent);
    }
  }
}