@import "../../../styles/breakpoints";

.Targets {
  &-content {
    @media (min-width: $breakpoint-tablet) {
      position: relative;
    }
    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 61px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 86px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 80px;
      }
      & img {
        width: 87px;
        height: 55px;
        @media (min-width: $breakpoint-tablet) {
          width: 153px;
          height: 97px;
        }
      }
    }
    &-title {
      margin-bottom: 36px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 73px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 90px;
      }
      & .Title-description {
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: $breakpoint-tablet) {
          max-width: 540px;
        }
      }
    }
    &-graphic {
      display: flex;
      justify-content: center;
      position: relative;
      @media (min-width: $breakpoint-tablet) {
        transform: scale(1.8) translateY(35px);
      }
      @media (min-width: $breakpoint-laptop) {
        transform: scale(1.3) translateY(35px) translateX(-55px);
        position: absolute;
        right: 200px;
        bottom: 80px;
      }
      @media (min-width: $breakpoint-fullHD) {
        right: 520px;
        bottom: 80px;
      }
      &-text {
        position: absolute;
        top: 12px;
        bottom: 0;
        left: -5px;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: var(--text-primary);
        font-size: 15px;
        font-weight: 600;
      }
      &-inside {
        &-firstLine {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          & img {
            &:first-child, &:last-child {
              padding-top: 24px;
            }
          }
        }
        &-secondLine {
          display: flex;
          align-items: flex-end;
          & img {
            &:nth-child(2) {
              margin-left: 18px;
              margin-right: 18px;
            }
            &:first-child, &:last-child {
              padding-bottom: 5px;
            }
          }
        }
      }
    }
  }
}