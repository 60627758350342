@import "../../styles/breakpoints";

.MainTemplate {
  position: relative;
  & .Navigation {
    margin-bottom: 38px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 60px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-bottom: 36px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 75px;
    }
  }
  & .Intro {
    margin-bottom: 140px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 225px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-bottom: 212px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 280px;
    }
  }
  & .HowItWork {
    margin-bottom: 83px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 178px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-bottom: 140px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 201px;
    }
  }
  & .Beta {
    margin-bottom: 59px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 130px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 156px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 113px;
    }
  }
  & .Targets {
    margin-bottom: 68px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 275px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-bottom: 232px;
    }
  }
  & .Future {
    margin-bottom: 120px;
  }
  & .Pilot {
    margin-bottom: 45px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: -80px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-bottom: 10px;
    }
  }
  & .Security {
    margin-bottom: 74px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 143px;
    }
  }
  & .Gallery {
    margin-bottom: 72px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 146px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 154px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 248px;
    }
  }
}