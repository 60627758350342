@import "../../../styles/breakpoints";

.Navigation {
  &.opened {
    background-color: #1d1d33;
    opacity: 0.97;
    @media (min-width: $breakpoint-laptop) {
      background-color: initial;
      opacity: 1;
    }
  }
  &-inside {
    padding-top: 28px;
    padding-left: 29px;
    padding-right: 34px;
    z-index: 3;
    @media (min-width: $breakpoint-tablet) {
      padding-left: 80px;
      padding-right: 80px;
    }
    @media (min-width: $breakpoint-laptop) {
      padding-top: 53px;
      padding-left: 130px;
      padding-right: 130px;
    }
    @media (min-width: $breakpoint-fullHD) {
      padding-top: 53px;
      padding-left: 315px;
      padding-right: 315px;
    }
    &-popup {
      display: none;
      height: calc(100vh - 62px);
      position: absolute;
      background-color: #1d1d33;
      left: 0;
      z-index: 1000;
      right: 0;
      top: 60px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      &.opened {
        display: flex;
        @media (min-width: $breakpoint-laptop) {
          display: none;
        }
      }

      &-inside {
        &-item {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 49px;

          &-element {
            color: var(--text-primary);
            font-size: 34px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (min-width: $breakpoint-laptop) {
        justify-content: initial;
      }

      &-left {
        @media (min-width: $breakpoint-laptop) {
          width: 20%;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 25%;
        }

        &-logo {
          display: flex;
          align-items: center;

          &-icon {
            margin-right: 6px;
            width: 39px;
            height: 39px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (min-width: $breakpoint-tablet) {
              width: 61px;
              height: 38px;
            }
            @media (min-width: $breakpoint-laptop) {
              width: 51px;
              height: 51px;
            }

            & img {
              width: 100%;
              height: 100%;
            }
          }

          &-brand {
            font-size: 17px;
            text-transform: uppercase;
            font-weight: 600;
            color: var(--text-primary);
            @media (min-width: $breakpoint-tablet) {
              font-size: 26px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 23px;
            }
          }
        }
      }

      &-center {
        display: none;
        width: 60%;
        justify-content: center;
        @media (min-width: $breakpoint-laptop) {
          display: flex;
        }
        @media (min-width: $breakpoint-fullHD) {
          justify-content: flex-start;
        }

        &-item {
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          color: var(--text-primary);
          display: inline-block;
          padding-bottom: 5px;
          background-image: linear-gradient(45deg, white, white);
          background-position: 0 100%;
          background-size: 0 1px;
          background-repeat: no-repeat;
          transition: background-size 0.3s ease-in-out, background-position 0s 0.3s ease-in-out;

          &:hover {
            background-position: 100% 100%;
            background-size: 100% 1px;
          }

          &:visited {
            color: var(--text-primary);
          }

          &:not(:last-child) {
            margin-right: 50px;
            @media (min-width: $breakpoint-fullHD) {
              margin-right: 93px;
            }
          }
        }
      }

      &-right {
        width: 20%;
        display: none;
        justify-content: flex-end;
        @media (min-width: $breakpoint-laptop) {
          display: flex;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 15%;
        }

        & button {
          min-width: 184px;
          @media (min-width: $breakpoint-fullHD) {
            min-width: 193px;
          }
        }
      }

      &-rightMobile {
        display: flex;
        color: var(--text-primary);
        width: 24px;
        height: 24px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        @media (min-width: $breakpoint-tablet) {
          width: 40px;
          height: 28px;
        }
        @media (min-width: $breakpoint-laptop) {
          display: none;
        }

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}