@import "../../../styles/breakpoints";

@keyframes AccentButtonAnimation {
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
}

.CustomButton {
  &.primary {
    & button {
      color: var(--button-primary-txt);
      border-radius: 10px;
      background-color: transparent;
      border: 2px solid var(--button-primary-border);
      padding: 21px 55px 21px 55px;
      @media (min-width: $breakpoint-tablet) {
        padding: 30px 102px 30px 102px;
      }
      @media (min-width: $breakpoint-laptop) {
        padding: 13px 55px 13px 55px;
      }
      @media (min-width: $breakpoint-fullHD) {
        padding: 14px 53px 14px 55px;
      }
      &:hover {
        font-weight: 600;
      }
    }
  }
  &.accent {
    & button {
      background: rgb(255,93,120);
      background: linear-gradient(107deg, var(--button-secondary-bkg-from) 0%, var(--button-secondary-bkg-to) 100%);
      background-size: 100% 100%;
      border: none;
      color: var(--button-secondary-text);
      border-radius: 6px;
      padding: 16px 50px 16px 50px;
      @media (min-width: $breakpoint-tablet) {
        padding: 25px 104px 25px 104px;
      }
      @media (min-width: $breakpoint-laptop) {
        padding: 17px 50px 17px 50px;
      }
      @media (min-width: $breakpoint-fullHD) {
        padding: 22px 87px 22px 87px;
      }
      &:hover {
        transition: background-size 0.3s ease-in-out, background-position 0s 0.3s ease-in-out;
        background-size: 400% 400%;
        background-position: 0 50%;
        font-weight: 600;
        animation: AccentButtonAnimation 3s ease infinite;
      }
    }
  }
  & button {
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 15px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
    }
  }
}