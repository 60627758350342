@import "../../../styles/breakpoints";

@keyframes HintAnimation {
  0%{ filter: blur(0) brightness(1); }
  100%{ filter: blur(2px) brightness(0.7); }
}

.Gallery {
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width: $breakpoint-tablet) {
    padding-left: 77px;
    padding-right: 77px;
  }
  @media (min-width: $breakpoint-laptop) {
    padding-left: 163px;
    padding-right: 163px;
  }
  @media (min-width: $breakpoint-fullHD) {
    padding-left: 388px;
    padding-right: 388px;
  }
  &-view {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px) brightness(0);
    z-index: 3;
    & img {
      max-width: 90vw;
      max-height: 90vh;
      overflow: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-content {
    &-title {
      margin-bottom: 43px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 57px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 96px;
      }
      @media (min-width: $breakpoint-fullHD) {
        margin-bottom: 80px;
      }
    }
    &-items {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -15px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: -30px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin: -15px;
      }
      &-item {
        position: relative;
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        @media (min-width: $breakpoint-tablet) {
          margin-bottom: 30px;
        }
        @media (min-width: $breakpoint-laptop) {
          width: calc(33.33333% - 30px);
          margin: 15px;
        }
        &:hover {
          & picture {
            animation: HintAnimation 500ms ease-in-out;
            filter: blur(2px) brightness(0.7);
          }
          & .hint {
            transition: opacity 300ms;
            opacity: 0.9;
          }
        }
        & img, & picture {
          width: 100%;
          height: 100%;
        }
        &-hint {
          opacity: 0;
          display: flex;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          justify-content: center;
          align-items: center;
          color: var(--text-primary);
          font-size: 26px;
          flex-wrap: wrap;
          text-align: center;
          font-weight: 500;
          &-inside-second {
            font-size: 16px;
            font-weight: 300;
          }
        }
      }
    }
  }
}