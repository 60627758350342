@import "../../../styles/breakpoints";

.Dot {
  &-content {
    height: 37px;
    width: 37px;
    border-radius: 53px;
    background-color: var(--dot-bkg);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $breakpoint-tablet) {
      height: 59px;
      width: 59px;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 53px;
      width: 53px;
    }
    &-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--text-primary);
      @media (min-width: $breakpoint-tablet) {
        font-size: 27px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 24px;
      }
    }
  }
}