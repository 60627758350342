@import "../../../styles/breakpoints";

.Footer {
  bottom: 0; left: 0; right: 0;
  position: absolute;
  height: 300px;
  overflow: hidden;
  z-index: 2;
  @media (min-width: $breakpoint-tablet) {
    height: 390px;
  }
  @media (min-width: $breakpoint-laptop) {
    height: 308px;
  }
  @media (min-width: $breakpoint-fullHD) {
    height: 540px;
  }
  &:before {
    content: '';
    background: var(--background-ternary);
    width: 200%;
    position: absolute;
    left: 50%;
    bottom: -80px;
    transform: translateX(-50%) rotate(355deg);
    height: 100%;
    border-radius: 0;
    z-index: -1;
    @media (min-width: $breakpoint-fullHD) {
      bottom: -30%;
    }
  }
  &-content {
    position: absolute;
    top: 155px;
    width: 100%;
    display: flex;
    justify-content: center;
    @media (min-width: $breakpoint-tablet) {
      top: 180px;
    }
    @media (min-width: $breakpoint-laptop) {
      top: 150px;
    }
    @media (min-width: $breakpoint-fullHD) {
      top: 320px;
    }
    &-inside {
      &-first {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 14px;
        @media (min-width: $breakpoint-tablet) {
          margin-bottom: 32px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin-bottom: 14px;
        }
        @media (min-width: $breakpoint-fullHD) {
          margin-bottom: 21px;
        }
        &-text {
          text-transform: uppercase;
          color: var(--text-primary);
          font-size: 23px;
          font-weight: 600;
          @media (min-width: $breakpoint-tablet) {
            font-size: 46px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 25px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 33px;
          }
        }
      }
      &-second {
        justify-content: center;
        display: flex;
        opacity: 0.7;
        color: var(--text-primary);
        font-size: 10px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 12px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 12px;
        }
      }
      &-third {
        justify-content: center;
        display: flex;
        opacity: 0.7;
        color: var(--text-primary);
        font-size: 12px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 12px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 12px;
        }
      }
    }
  }
}
