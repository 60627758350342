@import "../../../styles/breakpoints";

.HowItWork {
  display: flex;
  justify-content: center;
  padding-left: 29px;
  padding-right: 29px;
  @media (min-width: $breakpoint-tablet) {
    padding-left: 110px;
    padding-right: 110px;
  }
  @media (min-width: $breakpoint-laptop) {
    padding-left: 139px;
    padding-right: 207px;
  }
  @media (min-width: $breakpoint-fullHD) {
    padding-left: 333px;
    padding-right: 439px;
  }
  &-bkg {
    @media (min-width: $breakpoint-laptop) {
      background-color: #363651;
      transform: rotate(-4.2deg) translateX(-23%) translateY(-215px);
      width: 200%;
    }
    @media (min-width: $breakpoint-fullHD) {
      transform: rotate(-8deg) translateX(-23%) translateY(-310px);
    }
    &-in {
      @media (min-width: $breakpoint-laptop) {
        width: 50%;
        transform: rotate(4.2deg) translateY(112px)  translateX(50%);
      }
      @media (min-width: $breakpoint-fullHD) {
        transform: rotate(8deg) translateY(50px)  translateX(50%);
      }
    }
  }
  &-content {
    &-title {
      margin: 0 auto 46px;
      max-width: 290px;
      @media (min-width: $breakpoint-tablet) {
        max-width: 546px;
        margin: 0 auto 66px;
      }
      @media (min-width: $breakpoint-laptop) {
        max-width: 424px;
        margin: 0 auto 67px;
      }
      @media (min-width: $breakpoint-fullHD) {
        margin: 0 auto 107px;
        max-width: 500px;
      }
    }
    &-switch {
      margin-bottom: 70px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 114px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 82px;
      }
      @media (min-width: $breakpoint-fullHD) {
        margin-bottom: 96px;
      }
    }
    &-picture {
      display: flex;
      justify-content: center;
      transition: 1s linear;
      &-desktop {
        display: none;
        @media (min-width: $breakpoint-laptop) {
          display: block;
          width: 936px;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 1148px;
        }
        & img {
          width: 100%;
        }
        &.technical {
          margin-left: 160px;
          margin-top: -20px;
          @media (min-width: $breakpoint-fullHD) {
            margin-left: 200px;
          }
        }
      }
      &-mobile {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media (min-width: $breakpoint-laptop) {
          display: none;
        }
        &-down {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &.flip {
            & .bkg {
              background-image: linear-gradient(to bottom, #06152b52 -90%, #1db38785 100%);
            }
          }
          &-bkg {
            height: 79px;
            width: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(to top, #66636ff2, #e2aa566e);
            opacity: 0.9;
            @media (min-width: $breakpoint-tablet) {
              height: 125px;
              width: 37px;
            }
            &-arrow {
              width: 15px;
              height: 22px;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (min-width: $breakpoint-tablet) {
                width: 24px;
                height: 35px;
              }
              & img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        &-pic {
          & img {
            width: 100%;
            height: 100%;
          }
          &.first {
            width: 310px;
            height: 212px;
            @media (min-width: $breakpoint-tablet) {
              width: 491px;
              height: 336px;
            }
          }
          &.second, &.techFirst {
            width: 259px;
            height: 193px;
            @media (min-width: $breakpoint-tablet) {
              width: 409px;
              height: 305px;
            }
          }
          &.third, &.techSecond {
            width: 310px;
            height: 200px;
            margin-top: -31px;
            @media (min-width: $breakpoint-tablet) {
              width: 490px;
              height: 317px;
              margin-top: -50px;
            }
          }
          &.techSecond {
            margin-bottom: -21px;
            margin-top: -22px;
          }
          &.fourth, &.techThird {
            width: 259px;
            height: 193px;
            @media (min-width: $breakpoint-tablet) {
              width: 409px;
              height: 305px;
            }
          }
          &.fifth {
            width: 310px;
            height: 212px;
            @media (min-width: $breakpoint-tablet) {
              width: 490px;
              height: 336px;
            }
          }
        }
        &-block {
          background-color: #06152b;
          padding: 14px 13px 15px 13px;
          width: calc(260px - 26px);
          border-radius: 12px;
          @media (min-width: $breakpoint-tablet) {
            width: 410px;
          }
          &-item {
            &:not(:last-child) {
              margin-bottom: 20px;
              @media (min-width: $breakpoint-tablet) {
                margin-bottom: 30px;
              }
            }
            &-title {
              display: flex;
              align-items: center;
              margin-bottom: 17px;
              @media (min-width: $breakpoint-tablet) {
                margin-bottom: 20px;
              }
              &-image {
                width: 27px;
                height: 27px;
                margin-right: 13px;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (min-width: $breakpoint-tablet) {
                  width: 43px;
                  height: 43px;
                }
                &-item {
                  width: 15px;
                  height: 15px;
                  @media (min-width: $breakpoint-tablet) {
                    width: 31px;
                    height: 31px;
                  }
                  & img {
                    width: 100%;
                    height: 100%;
                  }
                }

                &.orange {
                  background-color: rgba(96, 81, 74, 0.2);
                }

                &.green {
                  background-color: rgba(60, 190, 125, 0.2);
                }
              }

              &-text {
                color: var(--text-primary);
                font-size: 14px;
                font-weight: 500;
                @media (min-width: $breakpoint-tablet) {
                  font-size: 21px;
                }
              }
            }
            &-list {
              max-width: 202px;
              padding-inline-start: 18px;
              margin-block-start: 0;
              margin-block-end: 0;
              @media (min-width: $breakpoint-tablet) {
                max-width: 303px;
              }
              &-item {
                color: var(--text-quadrary);
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                @media (min-width: $breakpoint-tablet) {
                  font-size: 18px;
                  line-height: 27px;
                }
              }
            }
          }
        }
      }
    }
  }
}