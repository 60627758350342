@import "../../../styles/breakpoints";

.Pilot {
  @media (min-width: $breakpoint-fullHD) {
    padding-left: 130px;
  }
  &-content {
    @media (min-width: $breakpoint-laptop) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      padding-left: 140px;
      padding-right: 20px;
    }
    @media (min-width: $breakpoint-fullHD) {
      padding-left: initial;
      padding-right: initial;
      max-width: 1300px;
      margin: 0 auto 0 auto;
    }
    &-left {
      &-title {
        margin-bottom: 56px;
        @media (min-width: $breakpoint-tablet) {
          margin-bottom: 100px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin-bottom: 72px;
        }

        & .Title-main {
          @media (min-width: $breakpoint-laptop) {
            justify-content: flex-start;
          }
        }

        & .Title-description {
          max-width: 260px;
          margin-left: auto;
          margin-right: auto;
          @media (min-width: $breakpoint-tablet) {
            max-width: 560px;
          }
          @media (min-width: $breakpoint-laptop) {
            margin-left: initial;
          }
        }
      }
      &-inside {
        max-width: 310px;
        margin: 0 auto 20px;
        @media (min-width: $breakpoint-tablet) {
          max-width: 593px;
          margin-bottom: 60px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin-bottom: 0;
        }

        &-item {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          &:last-child {
            & .separator {
              display: none !important;
            }
          }

          &:not(:last-child) {
            margin-bottom: 36px;
            @media (min-width: $breakpoint-tablet) {
              margin-bottom: 48px;
            }
          }

          &-left {
            width: 54px;
            display: flex;
            justify-content: flex-start;
            @media (min-width: $breakpoint-tablet) {
              width: 94px;
            }
            @media (min-width: $breakpoint-laptop) {
              flex-wrap: wrap;
            }

            &-separator {
              display: none;
              @media (min-width: $breakpoint-laptop) {
                width: 70px;
                display: block;
                position: relative;
              }
              &-item {
                position: absolute;
                top: 16px;
                left: 25px;
                height: 40px;
                width: 1px;
                background-color: #595a6d;
                @media (min-width: $breakpoint-fullHD) {
                  height: 60px;
                }
              }
            }

            &-image {
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (min-width: $breakpoint-tablet) {
                width: 50px;
                height: 50px;
              }

              & img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &-right {
            width: calc(100% - 54px);

            &-title {
              font-size: 13px;
              font-weight: 500;
              color: var(--text-primary);
              text-transform: uppercase;
              margin-bottom: 10px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 18px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 16px;
              }
              @media (min-width: $breakpoint-laptop) {
                margin-bottom: 16px;
              }
              @media (min-width: $breakpoint-fullHD) {
                margin-bottom: 30px;
              }
            }

            &-desc {
              color: var(--text-sextary);
              font-size: 13px;
              line-height: 24px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 18px;
                line-height: 32px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 16px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
    &-right {
      @media (min-width: $breakpoint-laptop) {
        margin-top: -60px;
      }
      &-screens {
        max-width: 375px;
        margin: 0 auto;
        @media (min-width: $breakpoint-tablet) {
          max-width: 767px;
        }

        &-arrow {
          display: flex;
          justify-content: center;

          &:nth-child(2) {
            margin-top: -87px;
            margin-bottom: -42px;
            @media (min-width: $breakpoint-tablet) {
              margin-top: -194px;
              margin-bottom: -90px;
            }
            @media (min-width: $breakpoint-laptop) {
              margin-top: -139px;
              margin-bottom: -63px;
            }
          }

          &:nth-child(4) {
            margin-top: -80px;
            margin-bottom: -28px;
            @media (min-width: $breakpoint-tablet) {
              margin-top: -162px;
              margin-bottom: -88px;
            }
            @media (min-width: $breakpoint-laptop) {
              margin-top: -139px;
              margin-bottom: -63px;
            }
          }

          & img {
            @media (min-width: $breakpoint-tablet) {
              height: 94px;
              width: 14px;
            }
            @media (min-width: $breakpoint-laptop) {
              height: 73px;
              width: 10px;
            }
          }
        }

        &-screen {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          & picture {
            width: 100%;

            & img {
              width: 100%;
              @media (min-width: $breakpoint-laptop) {
                width: 520px;
              }
              @media (min-width: $breakpoint-fullHD) {
                width: 620px;
              }
            }
          }
        }
      }
    }
  }
}