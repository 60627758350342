@import "../../../styles/breakpoints";

.Future {
  @media (min-width: $breakpoint-tablet) {
    padding-left: 27px;
    padding-right: 27px;
  }
  @media (min-width: $breakpoint-laptop) {
    padding-left: 42px;
    padding-right: 42px;
  }
  & .BkgBlock {
    box-shadow: 10px 30px 50px 0 rgba(30, 30, 61, 0.5);
    padding-bottom: 40px;
    @media (min-width: $breakpoint-tablet) {
      padding-bottom: 163px;
      border-radius: 24px;
      box-shadow: 10px 30px 50px 0 rgba(30, 30, 61, 0.5);
    }
    @media (min-width: $breakpoint-laptop) {
      max-width: 1071px;
      background-color: #363651;
      margin: 0 auto;
      border-radius: 24px;
      padding-top: 45px;
      padding-bottom: 70px;
      border-top: 1px solid #2e2f4a;
      box-shadow: 10px 30px 50px 0 rgba(30, 30, 61, 0.5);
    }
    & .BulletList-content-inside {
      @media (min-width: $breakpoint-tablet) {
        max-width: 412px;
      }
      &-item {
        max-width: 412px;

        @media (min-width: $breakpoint-laptop) {
          width: 100% !important;;
        }
        &-title {
          text-align: center;
        }
      }
    }
  }
  &-content {
    & .BulletList-content-inside {
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 50px;
      }
      &-item {
        &:nth-child(1) {
          @media (min-width: $breakpoint-laptop) {
            width: calc(100% - 350px);
          }
          @media (min-width: $breakpoint-fullHD) {
            width: calc(100% - 400px);
          }
        }

        &:nth-child(2) {
          @media (min-width: $breakpoint-laptop) {
            width: 350px;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 400px;
          }
        }
      }
    }
    &-title {
      margin-bottom: 60px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 115px;
      }
      & .Title-description {
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-skeleton {
      max-width: 250px;
      margin: 0 auto 80px;
      @media (min-width: $breakpoint-tablet) {
        max-width: 556px;
      }
      @media (min-width: $breakpoint-laptop) {
        max-width: initial;
      }

      &-inside {
        @media (min-width: $breakpoint-laptop) {
          border-top: 1px solid #52536a;
          max-width: 1071px;
          margin: 0 auto;
          padding-top: 65px;
        }
        &-title {
          color: var(--target-text-third);
          font-size: 13px;
          line-height: 24px;
          text-transform: uppercase;
          text-align: center;
          font-weight: 500;
          margin-bottom: 15px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 18px;
            margin-bottom: 21px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 16px;
          }
        }
        &-desc {
          color: var(--text-primary);
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          @media (min-width: $breakpoint-tablet) {
            font-size: 18px;
            max-width: 330px;
            margin: 0 auto;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 16px;
            max-width: initial;
          }
        }
      }
    }
    &-bullet {
      margin-bottom: 30px;
    }
    &-gallery {
      max-width: 210px;
      margin: 0 auto;
      @media (min-width: $breakpoint-tablet) {
        max-width: 600px;
      }
      @media (min-width: $breakpoint-laptop) {
        max-width: 530px;
      }
      &-inside {
        @media (min-width: $breakpoint-tablet) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &-item {
          &:not(:last-child) {
            margin-bottom: 90px;
            @media (min-width: $breakpoint-tablet) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
