:root {
  --text-primary: white;
  --text-secondary: #a0a1b9;
  --text-ternary: #cdcdd4;
  --text-accent: #038cfe;
  --text-quadrary: #9797a3;
  --text-pentary: #cbcbd2;
  --text-sextary: #aeaeb9;
  --button-primary-border: #c8c6dd;
  --button-primary-txt: white;
  --button-secondary-bkg-from: rgba(255,93,120,1);
  --button-secondary-bkg-to: rgba(255,151,108,1);
  --button-secondary-text: white;

  --dot-bkg: #1275f3;
  --separator: #bcbcbc;
  --smallSeparator: #1275f3;
  --background-secondary: #1c1d34;
  --background-primary: #2e2f46;
  --background-ternary: #474860;

  --switch-inactive-bkg: #515168;
  --switch-inactive-text: rgba(255, 255, 255, 0.7);
  --switch-active-bkg: white;
  --switch-active-text: #292a3d;

  --target-text-first: #82c8ff;
  --target-text-second: #43acff;
  --target-text-third: #018efe;

  --security-item-border: #52536a;
}