@import "../../../styles/breakpoints";

.Security {
  &-content {
    &-title {
      margin-bottom: 82px;
      & .Title-description {
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: $breakpoint-tablet) {
          max-width: 330px;
        }
      }
    }
    &-inside {
      max-width: 303px;
      margin: 0 auto;
      @media (min-width: $breakpoint-tablet) {
        max-width: 371px;
      }
      @media (min-width: $breakpoint-laptop) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        max-width: initial;
      }
      &-item {
        border: 1px solid var(--security-item-border);
        height: 204px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        @media (min-width: $breakpoint-laptop) {
          height: 223px;
          width: 326px;
        }
        &:not(:last-child) {
          margin-bottom: 45px;
          @media (min-width: $breakpoint-laptop) {
            margin-bottom: initial;
            margin-right: 28px;
          }
        }
        @media (min-width: $breakpoint-tablet) {
          height: 254px;
        }
        &-in {
          &-icon {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            &-element {
              height: 83px;
              width: 83px;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (min-width: $breakpoint-tablet) {
                height: 107px;
                width: 107px;
              }
              @media (min-width: $breakpoint-tablet) {
                height: 92px;
                width: 92px;
              }
              & img {
                width: 100%;
                height: 100%;
              }
            }
          }
          &-text {
            color: var(--text-primary);
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
            max-width: 168px;
            margin: 0 auto;
            text-align: center;
            @media (min-width: $breakpoint-tablet) {
              font-size: 18px;
              line-height: 28px;
              max-width: 232px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 16px;
              line-height: 24px;
              max-width: 207px;
            }
          }
        }
      }
    }
  }
}