@import "../../../styles/breakpoints";

.BulletList {
  &-content {
    &-inside {
      max-width: 334px;
      margin: 0 auto 60px;
      @media (min-width: $breakpoint-tablet) {
        max-width: 610px;
        margin-bottom: 82px;
      }
      @media (min-width: $breakpoint-laptop) {
        max-width: 936px;
        display: flex;
        flex-wrap: wrap;
      }
      &-item {
        max-width: 320px;
        @media (min-width: $breakpoint-tablet) {
          max-width: 610px;
        }
        @media (min-width: $breakpoint-laptop) {
          max-width: initial;
          width: 50%;
        }
        &:not(:last-child) {
          margin-bottom: 34px;
          @media (min-width: $breakpoint-tablet) {
            margin-bottom: 55px;
          }
        }
        &:nth-child(1) {
          & .title {
            color: var(--target-text-first);
          }
        }
        &:nth-child(2) {
          & .title {
            color: var(--target-text-second);
          }
        }
        &:nth-child(3) {
          & .title {
            color: var(--target-text-third);
          }
        }
        &-image {
          margin-bottom: 20px;
          width: 37px;
          height: 37px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: $breakpoint-tablet) {
            margin-bottom: 28px;
            width: 48px;
            height: 48px;
          }
          & img {
            width: 100%;
            height: 100%;
          }
        }
        &-title {
          font-size: 13px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 20px;
          letter-spacing: 0.65px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 18px;
            margin-bottom: 28px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 16px;
          }
          &.neutral {
            color: var(--text-primary) !important;
          }
        }
        &-list {
          &-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            &-icon {
              padding-top: 0;
              margin-right: 10px;
              height: 7px;
              width: 7px;
              @media (min-width: $breakpoint-tablet) {
                padding-top: 8px;
                height: 13px;
                width: 13px;
              }
              & img {
                width: 100%;
                height: 100%;
              }
            }
            &-text {
              width: calc(100% - 7px);
              font-size: 13px;
              line-height: 24px;
              font-weight: 400;
              color: var(--text-primary);
              @media (min-width: $breakpoint-tablet) {
                font-size: 18px;
                line-height: 34px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 14px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}