@import "../../../styles/breakpoints";

.Switch {
  display: flex;
  justify-content: center;
  &-content {
    background-color: var(--switch-inactive-bkg);
    width: fit-content;
    display: flex;
    border-radius: 10px;
    height: 42px;
    padding-left: 5px;
    @media (min-width: $breakpoint-tablet) {
      height: 53px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 54px;
    }
    &-item {
      display: flex;
      align-items: center;
      width: 127px;
      cursor: pointer;
      height: 42px;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        height: 53px;
        width: 160px;
      }
      @media (min-width: $breakpoint-laptop) {
        height: 53px;
        width: 172px;
      }
      @media (min-width: $breakpoint-fullHD) {
        height: 54px;
      }
      &.inactive {
        &:hover {
          & .text {
            color: var(--text-primary);
          }
          & .icon {
            filter: brightness(100);
          }
        }
      }
      &.active {
        height: 34px;
        margin: auto auto;
        background-color: var(--switch-active-bkg);
        border-radius: 10px;
        @media (min-width: $breakpoint-tablet) {
          height: 42px;
        }
        @media (min-width: $breakpoint-fullHD) {
          height: 44px;
        }
        & .text {
          color: var(--switch-active-text);
        }
      }
      &-text {
        color: var(--switch-inactive-text);
        font-weight: 500;
        font-size: 13px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 20px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 18px;
        }
      }
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 13px;
        width: 12px;
        height: 12px;
        @media (min-width: $breakpoint-tablet) {
          width: 15px;
          height: 15px;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 15px;
          height: 15px;
        }
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}