@import "../../../styles/breakpoints";

.Contact {
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width: $breakpoint-tablet) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (min-width: $breakpoint-laptop) {
    padding-left: 245px;
    padding-right: 209px;
  }
  @media (min-width: $breakpoint-fullHD) {
    padding-left: 459px;
    padding-right: 460px;
  }
  &-content {

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 380px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 500px;
    }
    @media (min-width: $breakpoint-laptop) {
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: initial;
      max-width: 765px;
      margin: 0 auto 400px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin: 0 auto 610px;
    }
    &-left {
      width: 242px;
      height: 242px;
      margin-bottom: 48px;
      @media (min-width: $breakpoint-tablet) {
        width: 359px;
        height: 359px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: initial;
      }
      & img {
        width: 100%;
        height: 100%;
      }
    }
    &-right {
      &-name {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 9px;
        color: var(--text-primary);
        text-align: left;
        @media (min-width: $breakpoint-tablet) {
          font-size: 44px;
        }
        @media (min-width: $breakpoint-laptop) {
          text-align: left;
          font-size: 36px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 40px;
          margin-bottom: 7px;
        }
      }
      &-position {
        color: var(--text-accent);
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 32px;
        text-align: left;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
          text-align: left;
        }
        @media (min-width: $breakpoint-laptop) {
          text-align: left;
          font-size: 15px;
          margin-bottom: 34px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
          margin-bottom: 43px;
        }
      }
      &-description {
        margin-bottom: 40px;
        @media (min-width: $breakpoint-tablet) {
          margin-bottom: 90px;
        }
        &-item {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          justify-content: flex-start;
          @media (min-width: $breakpoint-tablet) {
            justify-content: flex-start;
          }
          @media (min-width: $breakpoint-laptop) {
            justify-content: initial;
          }
          &-label {
            margin-right: 20px;
          }
          &-text {
            color: var(--text-primary);
            font-size: 13px;
            @media (min-width: $breakpoint-tablet) {
              font-size: 18px;
            }
          }
        }
      }
      &-button {
        width: 100%;
        display: flex;
        justify-content: center;
        @media (min-width: $breakpoint-laptop) {
          justify-content: flex-start;
        }
        & button {
          min-width: 202px;
          @media (min-width: $breakpoint-tablet) {
            min-width: 333px;
          }
          @media (min-width: $breakpoint-laptop) {
            min-width: 264px;
            height: 59px;
          }
          @media (min-width: $breakpoint-fullHD) {
            min-width: 272px;
            height: 59px;
          }
        }
      }
    }
  }
}